<template>
  <v-container>
    <loader v-if="isLoading" message="Loading ...." />
    <div class="pt-6 pa-0" v-else>
      <s-tabs class="mb-8">
        <v-tab :to="{ name: 'edgeAnalytics' }"> Analytics </v-tab>
        <v-tab :to="{ name: 'edgeDetails' }"> Details </v-tab>
        <v-tab :to="{ name: 'edgeLogs' }"> Logs </v-tab>
      </s-tabs>
      <router-view :id="id" />
    </div>
  </v-container>
</template>
<script>
import Loader from '@/components/cards/Loader'
import { SimpleEventBus } from '@/services/core/EventBus'
import { mapGetters } from 'vuex'
export default {
  name: 'EdgeDetails',
  components: {
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      app: 'edge/currentApp',
    }),
  },
  methods: {
    async getAppDetails() {
      this.isLoading = true
      await this.$store.dispatch('edge/getAppDetails', this.$route.params.id)
      this.isLoading = false
    },
  },
  watch: {
    app: {
      handler(app) {
        SimpleEventBus.$emit('updateHeader', this.app.name)
      },
      immediate: true,
    },
  },
  async created() {
    await this.getAppDetails()
  },
  destroyed() {
    this.$store.dispatch('edge/clearCurrentApp')
    this.$store.dispatch('edge/clearCurrentAppLogs')
    SimpleEventBus.$emit('updateHeader', null)
  },
}
</script>
<style lang="scss" scoped></style>
