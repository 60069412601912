import { render, staticRenderFns } from "./Edge.vue?vue&type=template&id=aa41cb0e&scoped=true"
import script from "./Edge.vue?vue&type=script&lang=js"
export * from "./Edge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa41cb0e",
  null
  
)

export default component.exports